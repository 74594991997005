<template>
  <div class="page flex-col">
    <div class="container-body flex-col">
      <!-- 中间部分标语 -->
      <div class="container">
      <div class="row block_3">
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/left-decorative-horizontal-bar.png"/>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="text_6">让你轻松设计网络通信方案</p>
        </div>
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../../assets/img/right-decorative-horizontal-bar.png"/>
          </p>
        </div>
      </div>
      </div>
     
      <div class="block_1 flex-col">
        <!-- 企业简介 -->
        <div class="block_4 flex-col">
          <div id="anchor-point-blurb" class="text-wrapper_2 flex-col"><span class="text_7">企业简介</span></div>
          <img  class="image_4" referrerpolicy="no-referrer" src="../../assets/img/company-profile-blue-back-left.png"  />
          <div class="text-wrapper_41 flex-col">
            <span class="text_91">
              智能通信设G者
            </span>
          </div>
          <div class="section_51 flex-col"></div> 
        </div>
        <!-- 企业文化 -->
        <div class="container">
        <div class="block_44 flex-col">
          <div id="anchor-point-culture" class="text-wrapper_22 flex-col"><span class="text_77">企业文化</span></div>
          <div class="row info-row">
            <div class="col-sm-6 col-md-4 three-side">
              <div class="card three-card">
                <img class="card-img-top" src="../../assets/img/corporate-culture.png" alt="Card image" style="width:100%">
                <div class="card-body">
                  <h4 class="card-title">企业文化</h4>
                  <p class="card-text">自强不息</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 three-side">
              <div class="card three-card">
                <img class="card-img-top" src="../../assets/img/corporate-vision.png" alt="Card image" style="width:100%">
                <div class="card-body">
                  <h4 class="card-title">企业愿景</h4>
                  <p class="card-text">智能通信设计者</p>
                </div>
              </div>
            </div> 
            <div class="col-sm-6 col-md-4 three-side">
              <div class="card three-card">
                <img class="card-img-top" src="../../assets/img/corporate-mission.png" alt="Card image" style="width:100%">
                <div class="card-body">
                  <h4 class="card-title">使命</h4>
                  <p class="card-text">让设计更简单</p>
                </div>
              </div>
            </div> 
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {
      menuIndex: 5,
      constants: {},
    };
  },
  created() {
    localStorage.setItem("menuIndex", this.menuIndex);
    console.log("aboutus-menuIndex：" +  this.menuIndex)
    // this.$router.push("",);
    // this.$router.push({path: "/", query: {pageIndex: this.menuIndex}});
    this.$nextTick(()=>{this.toLocal()});
  },
  mounted() {
    let _this=this;
    _this.$nextTick(function(){
        window.addEventListener('scroll',_this.handleScroll)
    })			
  },
  methods: {
    toLocal(){
      //查找存储的锚点id
      let Id=localStorage.getItem('toId');
      console.log("Id：" +  Id)
      let toElement=document.getElementById(Id);
      //锚点存在跳转
      if(Id){
        toElement.scrollIntoView();
      }
    }
    
  },

destroyed() {
	localStorage.setItem('toId','');
}
};
</script>
<style scoped lang="css" src="../../assets/aboutUs.css" />